
@import "~bootstrap/dist/css/bootstrap.css";

/* main site fonts */
@font-face {
  font-family: 'Futura Bold';
  font-style: bold;
  src: url('/assets/font/futura-bold.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Futura Black';
  font-style: bold;
  src: url('/assets/font/Futura-Black.ttf') format('truetype'); 
}
@font-face {
  font-family: 'dpcomic';
  font-style: bold;
  src: url('/assets/font/dpcomic.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Futura';

  src: url('/assets/font/futura.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Futura MDBT';

  src: url('/assets/font/futura-mdbt.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Futura EXBT';

  src: url('/assets/font/futura-extrablack.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Arcade Classic';

  src: url('/assets/font/arcadeclassic.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Futura Hv';

  src: url('/assets/font/Futura-Heavy.ttf') format('truetype'); 
}

@font-face {
  font-family: 'Quicksand Bold';

  src: url('/assets/font/Quicksand_Bold.otf') format('opentype'); 
}

@font-face {
  font-family: 'Avenir Black';
  font-style: bold;
  src: url('/assets/font/Avenir-Black-03.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Avenir BlackOblique ';
  font-style: bold;
  src: url('/assets/font/Avenir-BlackOblique-04.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Avenir Book';
  font-style: bold;
  src: url('/assets/font/Avenir-Book-01.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Avenir BookOblique ';
  font-style: bold;
  src: url('/assets/font/Avenir-BookOblique-02.ttf') format('truetype'); 
}

@font-face {
  font-family: 'Avenir Heavy';
  font-style: bold;
  src: url('/assets/font/Avenir-Heavy-05.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Avenir Light';
  font-style: bold;
  src: url('/assets/font/Avenir-Light-07.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Avenir Medium';
  font-style: bold;
  src: url('/assets/font/Avenir-Medium-09.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Avenir Roman';
  font-style: bold;
  src: url('/assets/font/Avenir-Roman-12.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Century Gothic';
  font-style: bold;
  src: url('/assets/font/Century-Gothic.ttf') format('truetype'); 
}




/* pizza site fonts */
@font-face {
  font-family: 'Courier New';
  font-style: bold;
  src: url('/assets/font/Courier New.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Haettenschweiler';
  font-style: bold;
  src: url('/assets/font/Haettenschweiler.ttf') format('truetype'); 
}

/* Pasta site fonts */
@font-face {
  font-family: 'Consolas';
  font-style: bold;
  src: url('/assets/font/Consolas.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Cafe Francoise';
  font-style: bold;
  src: url('/assets/font/Cafe Francoise_D.otf') format('opentype'); 
}
/* Panini site fonts */
@font-face {
  font-family: 'Doergon';
  font-style: bold;
  src: url('/assets/font/Doergon.ttf') format('truetype'); 
}





body{
  	font-family: 'Avenir Black';
}/* You can add global styles to this file, and also import other style files */

.mt-20{margin-top:20px;}
.w-140{ width:140px!important;}
.w-120{width:120px!important;}

.ext-links{
    margin-top:20px;
    padding-top:10px;
   
}
.is_mobile{
	display:none;	
}
.is_web{
	display:block;	
}

.btn-sty{font-size: 10px!important;}

.loc-content{ font-size: 14px;}

.center-align{
	text-align:center;
}
.ft-16{
	font-size: 16px;
}




.close{
	color:#fff;
}
/* .modal {

   top: 100px;
   right: 100px;
   bottom: 0;
   left: 0;
 
   overflow: auto;
   overflow-y: auto;
} */
.ismobilemenu{
	display:none;
}
.navback{
	font-family: Consolas;
    text-transform: uppercase;
    letter-spacing: 1.5px;
	color: #666d72;
}

.pl-20{
	padding-left: 20px;
}
.margin-cts{
  margin-top:50px;
  margin-bottom:50px;
}
.mt-40{
  margin-top:40px;
}
.mt-30{
  margin-top:30px;
}
.mb-30{
   margin-bottom:30px;
}
.mb-20{
   margin-bottom:20px;
}
.mb-50{
	 margin-bottom:50px;
}
.mt-50{
	margin-top:50px;
}

.mb-30{
	margin-bottom:30px;
}
.mt-20{
	margin-top:20px;
}
.mt-90{
	
  margin-top: 29px;
}
.restaurant-info-details{
	margin-left:40px;
}
.pt-20{
	padding-top: 20px;
}
.pt-30{
  padding-top: 30px;
}
.pt-40{
  padding-top: 40px;
}
.pb-20{
  padding-bottom: 20px;
}
.pb-30{
  padding-bottom: 30px;
}
.pb-40{
  padding-bottom: 40px;
}
.pb-10{
  padding-bottom: 10px;
}
.ft-size-13{
  font-size:13px;
}
.mt-84{
  margin-top:84px;
}


.modal-backdrop {
	z-index: 998!important;
}

@media (max-width:575px){

  .is_mobile{
    display:block!important;	
  }
  .is_web{
    display:none!important;	
  }
}

/*.cc-color-override--193597100 .cc-btn:hover, .cc-color-override--193597100 .cc-btn:focus{
	background-color: #FFD700!important;
}*/


